// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apps-index-jsx": () => import("./../src/pages/apps/index.jsx" /* webpackChunkName: "component---src-pages-apps-index-jsx" */),
  "component---src-pages-apps-magnus-account-delete-jsx": () => import("./../src/pages/apps/magnus/account-delete.jsx" /* webpackChunkName: "component---src-pages-apps-magnus-account-delete-jsx" */),
  "component---src-pages-apps-toolbox-for-magnus-account-delete-jsx": () => import("./../src/pages/apps/toolbox-for-magnus/account-delete.jsx" /* webpackChunkName: "component---src-pages-apps-toolbox-for-magnus-account-delete-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-index-jsx": () => import("./../src/pages/industries/index.jsx" /* webpackChunkName: "component---src-pages-industries-index-jsx" */),
  "component---src-pages-industries-oil-distribution-jsx": () => import("./../src/pages/industries/oil-distribution.jsx" /* webpackChunkName: "component---src-pages-industries-oil-distribution-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-solutions-magnus-app-jsx": () => import("./../src/pages/solutions/magnus-app.jsx" /* webpackChunkName: "component---src-pages-solutions-magnus-app-jsx" */),
  "component---src-pages-solutions-magnus-monitor-jsx": () => import("./../src/pages/solutions/magnus-monitor.jsx" /* webpackChunkName: "component---src-pages-solutions-magnus-monitor-jsx" */),
  "component---src-pages-solutions-magnus-platform-jsx": () => import("./../src/pages/solutions/magnus-platform.jsx" /* webpackChunkName: "component---src-pages-solutions-magnus-platform-jsx" */),
  "component---src-pages-solutions-overview-jsx": () => import("./../src/pages/solutions/overview.jsx" /* webpackChunkName: "component---src-pages-solutions-overview-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

